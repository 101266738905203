<template>
    <div class="content">
        <div class="title">
            SIDOC
            <div style="font-size:14px;">evn：{{env.NODE_ENV}}</div>
        </div>

        <div class="formDiv">
            <div class="formItem">
                <input type="text" v-model="loginName" placeholder="用户名">
            </div>
            <div class="formItem">
                <input type="password" v-on:keyup.enter="login" v-model="user_pwd" placeholder="密码">
            </div>
            <div class="formItem">
                <button ref="loginButton" type="button" @click="login">登录</button>
            </div>
        </div>

        <div class="contact">
            @2022 SIDOC V{{config.version}}
        </div>
            
    </div>
</template>


<script>
import config from '../../../package.json'

export default {
    data(){
        return {
            loginName: "",
            user_pwd: "",
            config:config,
            env:process.env,
        }
    },
    methods:{
        login:function(){
            let that = this;
            this.$refs.loginButton.innerText = "登录..."
            this.$refs.loginButton.disabled = true;

            let params = {"loginName":this.loginName,"userPwd":this.user_pwd};
            this.$api.request({
                url: process.env.VUE_APP_BASE_URL_USER + '/login',
                data:params,
                method: 'post',
            }).then(res => {
                if(res.code == 0 && res.data.userId === 1){
                    localStorage.setItem("TOKEN",res.data.token);
                    that.$router.replace("/");
                }else{
                    that.$refs.loginButton.innerText = "登录"
                    that.$refs.loginButton.disabled = false;
                    that.$message.error({center:true,message:"登录失败"});
                }
            });
        }
    }
}
</script>

<style scoped>

.title{
   text-align: center;
   margin-top: 80px;
   font-size: 38px;
}

.formDiv{
   width: fit-content;
   margin-top: 30px;
   margin-left: auto;
   margin-right: auto;

   padding: 20px;
   border-radius: 6px;    
}

.formDiv .formItem{
   margin: 18px;
   width: 240px;
}
.formDiv .formItem input{ 
   padding: 6px;
   border-radius: 4px;
   border: 1px solid #ccc;
   box-sizing: border-box;
   width: 100%;
}
.formDiv .formItem button{
   padding: 6px;
   border-radius: 4px;
   border: 1px solid #ccc;
   cursor: pointer;
   width: 240px;
}

.contact{
   width: 100%;
   position: absolute;
   bottom: 2px;
   font-size: 14px;
   color: gray;
   text-align: center;
}
.contact a{
   color: gray;
}
</style>